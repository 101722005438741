import React from "react";
const About = () => {
    return (
        <div
            name="about"
            id="about"
            className="w-full bg-white text-black"
        >

            <div className="flex flex-col justify-center  items-center w-full h-full">
                <div className=" py-8   border-[#abcae7] my-20  border-4 flex flex-col justify-center items-center w-3/4 shadow-[12px_12px_2px_2px_rgb(32,182,210,0.3)] " >
                    <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-center pb-1 '>About Me</p>
                    <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8 mb-4">

                        <div></div>
                    </div>
                    <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
                        <div className="text-2xl sm:text-left text-2xl ">
                            <p>
                                Hi. I'm Sarah Wang, nice to meet you. Please take a look around.
                            </p>
                        </div>
                        <div>
                            <p>
                                {" "}
                                <p className="py-2">HELLO AND WELCOME!</p>

                                <p className="py-2 ">I am a Front-end Web Developer and with a passion for creating user-friendly, accessible websites and am eager to learn and grow in the field.  I am a creative problem solver who is always searching for ways to make the web development process more efficient and effective.</p>

                                <p className="py-2">As my former experience on construction management, I have a keen eye for detail and a passion for my code quality.</p>

                                <p className="py-2">When I am not behind my computer, you will find me either in my garden or on my bike exploring new trails.</p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
                <div className=' w-full flex justify-center items-center flex-col mb-7'>
                    <p className='text-4xl font-bold inline border-b-4 border-cyan-500 text-center pb-1'>My skills</p>
                    <p className='py-4 text-2xl'>I enjoy diving into and learning new things. Here's a list of technologies I've worked with</p>
                </div>
                <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>HTML</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>CSS/ SASS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>JAVASCRIPT</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>REACT</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>GITHUB</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>PHP</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>WORDPRESS</p>
                    </div>
                    <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                        <p className='my-4'>SHOPIFY</p>
                    </div>




                </div>
            </div>
        </div>

    );
};
export default About;
