import loading from '../assets//loading.gif';

const Loading = () => {

    return (
        <img src={loading} alt="Loading" className="loading" id="loading" />
    )
}

export default Loading
