import React from 'react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import me from '../assets/me.png';
import { Link } from 'react-router-dom';


const Home = () => {
    return (
        <div
            name="home"
            className="home h-screen w-full bg-[#f4fbfd]"
        >
            <div className="max-w-screen-lg mx-auto flex flex-col items-center justify-center h-full px-14 md:flex-row">
                <div className="flex flex-col justify-center h-full">
                    <h2 className="text-3xl sm:text-5xl font-bold text-black">
                        I'm a Front Web Developer
                    </h2>
                    <p className="text-gray-500 py-3 max-w-md">
                        I work in graphics design and web development.
                        Currently, I love to work on web application using new technologies tools.
                    </p>
                    <div>
                        <Link
                            to="/works"
                            smooth
                            duration={500}
                            className="group text-black w-fit px-6 py-3 my-2 flex items-center  bg-gradient-to-r from-white to-[#abcae7] cursor-pointer"
                        >
                            My Projects
                            <span className="group-hover:rotate-90 duration-300">
                                <HiArrowNarrowRight size={25} className="ml-3" />
                            </span>
                        </Link>
                    </div>
                </div>
                <div>
                    <img
                        src={me}
                        alt="my portfolio"
                        className="rounded-2xl mx-auto w-4/5  "
                    />
                </div>
            </div>
        </div>
    );

};
export default Home;