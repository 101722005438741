import React from 'react';
import { useState, useEffect } from 'react';
import Loading from './Loading'
import {
    FaLocationArrow,
} from 'react-icons/fa';
import { BiMailSend, BiPhone } from "react-icons/bi";
const Contact = () => {
    const restPath = 'https://qingqing.ca/live-portolio/wp-json/wp/v2/pages/42/'
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath, { mode: 'cors', headers: { "origin": "" } })
            if (response.ok) {
                const data = await response.json()
                setData(data)
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])


    return (
        <>
            {isLoaded ?
                <>
                    <section className="relative h-screen justify-center py-[120px] sm:h-[70px] h-max mb-10 md:h-max">
                        <div className="container mx-auto">
                            <div className="w-full px-4 w-3/4  justify-center">
                                <div className="relative rounded-lg bg-[#f4fbfd] p-8 m-8 shadow-lg">
                                    <div className="mb-12 max-w-[870px] lg:mb-0">
                                        <span className="text-primary mb-4 block text-base font-semibold">
                                            Contact Us
                                        </span>
                                        <h2
                                            className="text-dark mb-6 text-[20px] font-bold uppercase sm:text-[20px] lg:text-[36px] xl:text-[40px]"
                                        >
                                            GET IN TOUCH WITH ME
                                        </h2>
                                        <p className="text-body-color mb-9 text-base leading-relaxed">
                                            If you are interesed in collaborating on a project or if you have any questions, please feel free to contact me.
                                        </p>
                                        <div className="mb-8 flex w-full max-w-[370px]">
                                            <div
                                                className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                                            >
                                                <FaLocationArrow size={24} />
                                            </div>
                                            <div className="w-full">
                                                <h4 className="text-dark mb-1 text-xl font-bold">Our Location</h4>
                                                <p className="text-body-color text-base">
                                                    5555 dr, Bcit, Downtown
                                                </p>
                                            </div>
                                        </div>
                                        <div className="mb-8 flex w-full max-w-[370px]">
                                            <div
                                                className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                                            >
                                                <BiPhone size={26} />
                                            </div>
                                            <div className="w-full">
                                                <h4 className="text-dark mb-1 text-xl font-bold">Phone Number</h4>
                                                <p className="text-body-color text-base">{restData.acf.phone_number}</p>

                                            </div>
                                        </div>
                                        <div className="mb-8 flex w-full max-w-[370px]">
                                            <div
                                                className="bg-primary text-primary mr-6 flex h-[60px] w-full max-w-[60px] items-center justify-center overflow-hidden rounded bg-opacity-5 sm:h-[70px] sm:max-w-[70px]"
                                            >
                                                <BiMailSend size={26} />
                                            </div>
                                            <div className="w-full">
                                                <h4 className="text-dark mb-1 text-xl font-bold">Email Address</h4>
                                                <p className="text-body-color text-base">{restData.acf.email}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <span className="absolute -top-10 -right-9 z-[-1]">
                                            <svg
                                                width="100"
                                                height="100"
                                                viewBox="0 0 100 100"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fill-rule="evenodd"
                                                    clip-rule="evenodd"
                                                    d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z"
                                                    fill="#3056D3"
                                                />
                                            </svg>
                                        </span>
                                        <span className="absolute -right-10 top-[90px] z-[-1]">
                                            <svg
                                                width="34"
                                                height="134"
                                                viewBox="0 0 34 134"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="31.9993"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 1.66665)"
                                                    fill="#13C296"
                                                />
                                            </svg>
                                        </span>
                                        <span className="absolute -left-7 -bottom-7 z-[-1]">
                                            <svg
                                                width="107"
                                                height="134"
                                                viewBox="0 0 107 134"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <circle
                                                    cx="104.999"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="104.999"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 104.999 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="90.3333"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 90.3333 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="75.6654"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 75.6654 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="31.9993"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 31.9993 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="60.9993"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 60.9993 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="17.3333"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 17.3333 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="132"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 132)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="117.333"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 117.333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="102.667"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 102.667)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="88"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 88)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="73.3333"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 73.3333)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="45"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 45)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="16"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 16)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="59"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 59)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="30.6666"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 30.6666)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="46.3333"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 46.3333 1.66665)"
                                                    fill="#13C296"
                                                />
                                                <circle
                                                    cx="2.66536"
                                                    cy="1.66665"
                                                    r="1.66667"
                                                    transform="rotate(180 2.66536 1.66665)"
                                                    fill="#13C296"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </section>
                </>
                :
                <Loading />
            }
        </>


    )
}
export default Contact