import React from 'react';
import {

    FaGithub,
    FaLinkedin,
    FaFortAwesomeAlt,
    FaRegFolderOpen,
    FaRegCommentAlt,
} from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BiHomeHeart } from "react-icons/bi";
import { BrowserRouter as Route, Routes, NavLink } from 'react-router-dom'

const Navbar = () => {
    return (
        <header className=' w-full h-[60px] fixed  z-20 flex justify-between items-center px-4 bg-white text-#0a192f-300 mb-3 border-b border-zinc-200' >
            <div>
                <NavLink to='/' end><h1 className=' effect-box font-thin text-2xl italic font-serif'>Sarah. W</h1></NavLink>
            </div>
            {/* header menu */}
            <nav className="site-navigation">
                <ul className='hidden md:flex gap-x-8'>
                    <li><NavLink to='/' end className="hover-underline-animation">Home</NavLink></li>
                    <li><NavLink to='/about' className="hover-underline-animation">About</NavLink></li>
                    <li><NavLink to='/works' className="hover-underline-animation">Works</NavLink></li>
                    <li><NavLink to='/contact' className="hover-underline-animation">Contact</NavLink></li>
                </ul>
            </nav>


            <div className="flex sm:hidden fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
                    <button type="button" className="inline-flex flex-col items-center justify-center px-5 border-gray-200 border-x hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600">
                        <BiHomeHeart size={20} />
                        <span className="text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"><NavLink to='/' end>Home</NavLink></span>
                    </button>
                    <button type="button" className="inline-flex flex-col items-center justify-center px-5 border-r border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group dark:border-gray-600">
                        <FaRegFolderOpen classname="gray-400" size={20} />
                        <span className="mt-2 text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"><NavLink to='/works'>Works</NavLink></span>
                    </button>
                    <button type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
                        <FaFortAwesomeAlt classname="gray-400" size={20} />
                        <span className="mt-2 text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"><NavLink to='/about'>About</NavLink></span>
                    </button>

                    <button type="button" className="inline-flex flex-col items-center justify-center px-5 border-gray-200 hover:bg-gray-50 dark:hover:bg-gray-800 group border-x dark:border-gray-600">
                        <FaRegCommentAlt size={20} />
                        <span className="mt-2 text-sm text-gray-500 dark:text-gray-400 group-hover:text-blue-600 dark:group-hover:text-blue-500"><NavLink to='/contact'>Contact</NavLink></span>
                    </button>
                </div>
            </div>


            {/* Social icons */}
            <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
                <ul>
                    <li className='w-[160px] h-[45px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#abcae7]'>
                        <a
                            className='flex justify-between items-center w-full text-white'
                            href=' https://www.linkedin.com/in/sarahwang0306/ '
                        >
                            Linkedin <FaLinkedin size={20} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[45px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#abcae7]'>
                        <a
                            className='flex justify-between items-center w-full text-white'
                            href=' https://github.com/sarahfwd?tab=repositories '
                        >
                            Github <FaGithub size={20} />
                        </a>
                    </li>
                    <li className='w-[160px] h-[45px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#abcae7]'>
                        <a
                            className='flex justify-between items-center w-full text-white'
                            href="mailto:wqingqing36@gmail.com"
                        >
                            Email <HiOutlineMail size={20} />
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
};
export default Navbar;