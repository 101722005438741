import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    FaGithub,
    FaDesktop,
} from 'react-icons/fa';

const Works = ({ featuredImage }) => {
    const restPath = 'https://qingqing.ca/live-portolio/wp-json/wp/v2/posts?_embed'
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath, { mode: 'cors', headers: { "origin": "" } })
            if (response.ok) {
                const data = await response.json()
                setData(data)
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])

    return (
        <div className="bg-primary h-screen sm: h-max text-black px-5 py-10 " id="projects">

            <div className='mt-20'>
                <div className='pb-8 w-full   flex justify-center items-center  flex-col '>
                    <p className='text-4xl font-bold inline border-b-4 black border-cyan-500'>
                        Works
                    </p>
                    <p className='py-6 text-1xl'>Check out some of my most recent work</p>
                </div>

                <div className='projects container mx-auto grid md:grid-cols-3 gap-10'>
                    {restData.map((project, i) => {
                        console.log("project=", project)
                        return (
                            <div name='work' className='w-full text-black bg-white'>

                                {/* Container */}
                                <div>
                                    {/* Grid Item */}
                                    <div
                                        style={{ backgroundImage: `url(${project._embedded['wp:featuredmedia']['0'].source_url})` }}
                                        className='shadow-lg shadow-[#040c16] group container rounded-md flex justify-center items-center mx-auto content-div'
                                    >

                                        {/* Hover Effects */}
                                        <div className='opacity-0 group-hover:opacity-100 flex justify-center items-center flex-col'>
                                            <span className=' text-lg font-bold text-white tracking-wider'>
                                                {project.title.rendered}
                                            </span>


                                            <p className='text-center'> {project.acf.project_tools_list}</p>
                                            <div className='pt-8 text-center'>
                                                <a href={project.acf.live_url}>
                                                    <button className='text-center rounded-lg px-3 py-2 m-1 bg-white text-gray-700 text-lg'>
                                                        <FaDesktop size={20} />
                                                    </button>
                                                </a>

                                                <a

                                                    href={project.acf.github_url}
                                                >
                                                    <button className='text-center rounded-lg px-3 py-2 m-1 bg-white text-gray-700 text-lg'>
                                                        <FaGithub size={20} />
                                                    </button>
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                    <div className=' uppercase text-lg text-center text-black py-3 tracking-wider'>
                                        {project.title.rendered}
                                    </div>
                                    <div className='flex items-center justify-center'>
                                        <a href={`/works/${project.slug}`} className="relative inline-flex items-center justify-center p-4 px-6 py-3 overflow-hidden font-medium text-indigo-600 transition duration-300 ease-out border-2 border-cyan-500 rounded-full shadow-md group">
                                            <span className="absolute inset-0 flex items-center justify-center w-full h-full text-white duration-300 -translate-x-full bg-cyan-500 group-hover:translate-x-0 ease">
                                                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                                            </span>
                                            <span className="absolute flex items-center justify-center w-full h-full text-black transition-all duration-300 transform group-hover:translate-x-full ease"><Link to={`/works/${project.slug}`}>View more details</Link></span>
                                            <span className="relative invisible"><Link to={`/works/${project.slug}`}>View more details</Link></span>
                                        </a>

                                    </div>
                                </div>
                            </div>

                        );
                    })}
                </div>
            </div>
        </div>
    )
};
export default Works;