import React from 'react';



const Footer = () => {

    return (
        <section className="bg-[#ececf0]  align-middle h-16 border-t-2   border-zinc-100 bottom-0 ">
            <div className='mt-5'>
                <p className="text-base leading-6 mx-2 text-center text-gray-400">
                    © 2023 Sarah.
                </p>

            </div>
        </section>
    )
};
export default Footer;