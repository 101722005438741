import { useParams } from 'react-router-dom';

const ProductDetails = () => {
    const { slug } = useParams();
    return (
        <div className="container">
            <h1>Products Details Page - {slug}</h1>
        </div>
    );
};

export default ProductDetails;