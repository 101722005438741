import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Loading from './Loading'
import {
    FaGithub,
    FaDesktop,
} from 'react-icons/fa';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Image } from "semantic-ui-react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';



const Work = ({ featuredImage }) => {
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            paritialVisibilityGutter: 60
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            paritialVisibilityGutter: 50
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            paritialVisibilityGutter: 30
        }
    };
    const { slug } = useParams();
    console.log(slug)
    const restPath = `https://qingqing.ca/live-portolio/wp-json/wp/v2/posts?_embed&slug=${slug}`
    console.log(restPath)
    const [restData, setData] = useState([])
    const [isLoaded, setLoadStatus] = useState(false)



    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(restPath)
            if (response.ok) {
                const data = await response.json()
                setData(data[0])
                setLoadStatus(true)
            } else {
                setLoadStatus(false)
            }
        }
        fetchData()
    }, [restPath])



    return (
        <>
            {isLoaded ?
                <>
                    {/* <Nav /> */}
                    <section className="bg-gray-100  flex flex-wrap text-black gap-4 items-center mx-auto w-full ">
                        <div className='sm:grid grid-cols-2 mt-20  '>
                            <div className="sm:text-left  lg:text-left mx-20  col-start-1 col-span-1 ">
                                <h1 className="sm:text-4xl text-3xl mt-20 p-5 font-bold text-yellow-700">
                                    {restData.title.rendered}
                                </h1>
                                <p className="px-5 max-w-xl leading-relaxed text-black sm:mx-auto lg:ml-0">
                                    {restData.acf.project_content}
                                </p>
                                <div className='pt-8 px-5'>
                                    <a href={restData.acf.live_url}>
                                        <button className='text-center rounded-lg px-3 py-2 m-1 bg-white text-gray-700 text-lg'>
                                            <FaDesktop size={30} />
                                        </button>
                                    </a>

                                    <a
                                        href={restData.acf.github_url}
                                    >
                                        <button className='text-center rounded-lg px-3 py-2 m-1 bg-white text-gray-700 text-lg'>
                                            <FaGithub size={30} />
                                        </button>
                                    </a>
                                </div>

                            </div>
                            <div className="col-start-2 col-span-1">
                                <img
                                    src={restData._embedded['wp:featuredmedia']['0'].source_url}
                                    className="w-full mx-auto mt-2 sm:w-10/12 lg:w-full"
                                    alt='banner'
                                />
                            </div>
                        </div>
                    </section>

                    <section className="px-14">
                        <h2 className="text-2xl py-2 font-bold  text-center text-yellow-700">
                            Tools
                        </h2>
                        <div className='w-full grid grid-cols-2 sm:grid-cols-4  text-black  gap-4 text-center py-8' >

                            {restData.mtags.map(tag =>
                                <div className='shadow-md shadow-[#040c16] hover:scale-110 duration-500'>
                                    <p className='my-4'>{tag.name}</p>
                                </div>)}
                        </div>
                    </section>

                    <section className='flex flex-col justify-center items-center w-full h-full'>
                        <div className=" design py-8 px-8 border-[#abcae7] my-10  border-4 flex flex-col justify-center items-center w-3/4 shadow-[12px_12px_2px_2px_rgb(32,182,210,0.3)]">
                            <Tabs>
                                <TabList className="mx-2 text-xl flex font-bold sm:text-2xl">
                                    <Tab>Design</Tab>
                                    <Tab>Development feature</Tab>
                                </TabList>

                                <TabPanel className="">
                                    <div className="my-4">
                                        <div className="my-2 ">
                                            <h2 className="text-2xl py-2 font-bold  text-center text-yellow-700">Design</h2>
                                            <p className='px-7'> {restData.acf.design.design}</p>
                                        </div>
                                        <div>
                                            <h2 className="text-2xl py-2 font-bold  text-center text-yellow-700">Color</h2>
                                            <div className='flex'>
                                                {restData.acf.color.map(dev => {
                                                    return (

                                                        <div className={`h-20 w-20 m-2 border-y border-x border-black align-middle]`} style={{ backgroundColor: `${dev.color}` }}>
                                                            <div className=' font-thin mt-7 mx-2'>{dev.color}</div>
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className='my-4'>
                                        {restData.acf.development.map(dev => {
                                            return (
                                                <div>
                                                    <h2 className="text-2xl py-2 font-bold  text-center text-yellow-700">{dev.header}</h2>
                                                    <p className='px-7'>{dev.content}</p>
                                                </div>
                                            )
                                        })}
                                    </div>

                                </TabPanel>
                            </Tabs>


                        </div>
                    </section>

                    <section className="flex flex-col justify-center items-center w-full h-full">
                        <section className=" design py-8  border-[#abcae7] my-10 border-4 flex flex-col justify-center items-center w-3/4 shadow-[12px_12px_2px_2px_rgb(32,182,210,0.3)] ">
                            <h3 className='text-2xl py-2 font-bold  text-center text-yellow-700'>Reflection</h3>
                            <p className='mx-7'>
                                {restData.acf.reflection}
                            </p>
                        </section>
                    </section>

                    <section className='shotscreen mx-10'>
                        <h2 className="text-2xl py-2 font-bold  text-center text-yellow-700">
                            Screen shots
                        </h2>
                        <Carousel
                            ssr
                            partialVisbile
                            // deviceType={deviceType}
                            itemclassName="image-item"
                            responsive={responsive}
                        >
                            {
                                restData._embedded["acf:attachment"].map(atta => {
                                    return (
                                        <Image
                                            draggable={false}
                                            style={{ width: "100%", height: "100%" }}
                                            src={atta.source_url}
                                            alt={atta.alt}
                                        />
                                    )
                                })
                            }
                        </Carousel>
                    </section>


                    <div className="button-group justify-center flex mx-20 mt-5">
                        <a href="#_" className="relative inline-flex items-center justify-start py-3 pl-4 pr-12 overflow-hidden font-semibold text-black transition-all duration-150 ease-in-out rounded hover:pl-10 hover:pr-6 bg-gray-50 group">
                            <span className="absolute bottom-0 left-0 w-full h-1 transition-all duration-150 ease-in-out bg-[#abcae7]  group-hover:h-full"></span>
                            <span className="absolute right-0 pr-4 duration-200 ease-out group-hover:translate-x-12">
                                <svg className="w-5 h-5 text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span className="absolute left-0 pl-2.5 -translate-x-12 group-hover:translate-x-0 ease-out duration-200">
                                <svg className="w-5 h-5 text-orange-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path></svg>
                            </span>
                            <span className="relative w-full text-left transition-colors duration-200 ease-in-out group-hover:text-black"><Link to="/works">View other work</Link></span>
                        </a>
                    </div>
                </>
                :
                <Loading />
            }
        </>
    )

}

export default Work
