import './App.css';
import About from "./components/About";
import Contact from "./components/Contact";
import Home from "./components/Home";
import Nav from "./components/Nav";
import ProductDetails from "./components/ProductDetails";
import Works from "./components/Works";
import Work from "./components/Work";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'


function App() {
  return (
    <div>
      <main id='main'>
        <Router>
          <header>
            <Nav />
          </header>
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/about' element={<About />} />
            <Route exact path='/works/:slug' element={<Work />} />
            <Route path='/test/:slug' element={<ProductDetails />} />
            <Route path='/test2' element={<ProductDetails />} />
            <Route exact path='/works' element={<Works />} />
            <Route exact path='/contact' element={<Contact />} />
          </Routes>
        </Router>
      </main>
      <Footer />
    </div >

  );
}
export default App;
